// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-content-page-tsx": () => import("./../../../src/components/templates/contentPage.tsx" /* webpackChunkName: "component---src-components-templates-content-page-tsx" */),
  "component---src-components-templates-empty-page-tsx": () => import("./../../../src/components/templates/emptyPage.tsx" /* webpackChunkName: "component---src-components-templates-empty-page-tsx" */),
  "component---src-components-templates-pool-page-tsx": () => import("./../../../src/components/templates/poolPage.tsx" /* webpackChunkName: "component---src-components-templates-pool-page-tsx" */),
  "component---src-components-templates-room-page-tsx": () => import("./../../../src/components/templates/roomPage.tsx" /* webpackChunkName: "component---src-components-templates-room-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-activities-tsx": () => import("./../../../src/pages/activities.tsx" /* webpackChunkName: "component---src-pages-activities-tsx" */),
  "component---src-pages-amenities-tsx": () => import("./../../../src/pages/amenities.tsx" /* webpackChunkName: "component---src-pages-amenities-tsx" */),
  "component---src-pages-awards-tsx": () => import("./../../../src/pages/awards.tsx" /* webpackChunkName: "component---src-pages-awards-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-concierge-tsx": () => import("./../../../src/pages/concierge.tsx" /* webpackChunkName: "component---src-pages-concierge-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-location-tsx": () => import("./../../../src/pages/location.tsx" /* webpackChunkName: "component---src-pages-location-tsx" */),
  "component---src-pages-ownership-tsx": () => import("./../../../src/pages/ownership.tsx" /* webpackChunkName: "component---src-pages-ownership-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-resort-map-tsx": () => import("./../../../src/pages/resort-map.tsx" /* webpackChunkName: "component---src-pages-resort-map-tsx" */),
  "component---src-pages-restaurants-tsx": () => import("./../../../src/pages/restaurants.tsx" /* webpackChunkName: "component---src-pages-restaurants-tsx" */),
  "component---src-pages-rooms-layout-tsx": () => import("./../../../src/pages/rooms-layout.tsx" /* webpackChunkName: "component---src-pages-rooms-layout-tsx" */),
  "component---src-pages-rooms-tsx": () => import("./../../../src/pages/rooms.tsx" /* webpackChunkName: "component---src-pages-rooms-tsx" */),
  "component---src-pages-shops-tsx": () => import("./../../../src/pages/shops.tsx" /* webpackChunkName: "component---src-pages-shops-tsx" */),
  "component---src-pages-wellness-tsx": () => import("./../../../src/pages/wellness.tsx" /* webpackChunkName: "component---src-pages-wellness-tsx" */)
}

